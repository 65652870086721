<template>
  <div :class="`event-member-select ${stateClass}`">
    <b-alert v-if="error" show dismissible variant="danger">{{ error }}</b-alert>
    <multiselect
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :searchable="true"
      @search-change="searchChanged"
      :internal-search="false"
      :loading="loading"
      :allow-empty="true"
      :placeholder="placeholderText"
    >
      <template #noResult> No event members found matching search. </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

import EventService from '@/lib/event-service-v2';
export default {
  components: { Multiselect },
  props: {
    eventId: null,
    currentEventMemberId: null,
    hideEntireGroup: null,
    state: null,
    placeholderText: {
      type: String,
      default: 'Find an event member'
    }
  },
  data() {
    return {
      error: null,
      loading: false,
      options: [],
      selected: null
    };
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    }
  },
  async mounted() {
    this.setOptions([]);
    await this.loadCurrent();
    this.search();
  },
  computed: {
    stateClass() {
      if (this.state === null || typeof this.state === 'undefined') return;

      if (this.state) {
        return 'is-valid';
      } else {
        return 'is-invalid';
      }
    }
  },
  methods: {
    async loadCurrent() {
      if (!this.currentEventMemberId) {
        return;
      }
      this.loading = true;

      const eventMember = await EventService.retrieveEventMember(this.currentEventMemberId);

      this.setOptions([eventMember]);
      this.selected = eventMember;

      this.loading = false;
    },

    async searchChanged(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.search(query), 300);
    },

    async search(query) {
      this.loading = true;

      const params = {
        eventId: this.eventId
      };

      if (query) {
        params.search = query;
      }

      try {
        const response = await EventService.listEventMembers(params);
        const options = [];

        for (const eventMember of response.data) {
          options.push(eventMember);
        }

        this.setOptions(options);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },

    setOptions(options) {
      let result = [];

      if (!this.hideEntireGroup) {
        result.push({
          name: 'Entire Group',
          id: ''
        });
      }

      result = [...result, ...options];

      this.options = result;
    }
  }
};
</script>
<style>
.is-invalid.event-member-select .multiselect__tags {
  border-color: #dc3545;
}
.is-valid.event-member-select .multiselect__tags {
  border-color: #28a745;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
