<template>
  <div>
    <div class="col-lg-6 col-sm-12 mb-8 pl-0">
      <div class="font-header font-medium text-2xl org-label">Select Organization</div>
      <div class="text-sm italic pb-2 sub-title">Please select an organization to see its schedules.</div>
      <OrganizationSelectV2 @organizationSelected="organizationSelected" />
    </div>

    <div v-if="organization && showEditMc" class="mb-4 pl-0">
      <div class="font-header font-medium text-2xl org-schedular">Organization's Schedules</div>
      <MessageCenterScheduleSelect
        v-if="organization"
        :reload="reload"
        :organizationId="organization.id"
        @schedulesUpdated="refreshTable"
      />
    </div>

    <div class="font-header font-medium text-2xl">Schedules</div>
    <b-table
      show-empty
      id="table"
      striped
      sort-icon-left
      class="history-table"
      :fields="tableColumns"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:cell(name)="row">
        {{ row.item.name }}
      </template>

      <template v-slot:cell(state)="data">
        <b-badge variant="success" v-if="data.value === 'ENABLED'">enabled</b-badge>
        <b-badge variant="warning" v-else-if="data.value === 'PAUSED'">paused</b-badge>
      </template>

      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <template #cell(delete)="row" v-if="showEditMc">
        <Button @onClick="deleteSchedule(row.item.name)" variant="outline" :loading="loading">
          <FontAwesomeIcon class="text-xl" :icon="['far', 'trash-can']" />
        </Button>
      </template>
    </b-table>

    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      pills
      aria-controls="table"
      align="center"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    />
  </div>
</template>

<script>
import Button from '@/components/ui/LoadingButton';
import MessageCenter from '@/lib/message-center';
import MessageCenterScheduleSelect from '@/components/MessageCenterScheduleSelect';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  components: { OrganizationSelectV2, MessageCenterScheduleSelect, Button },
  watch: {
    organization: function () {
      this.refreshTable();
    }
  },
  data() {
    return {
      organization: null,
      reload: 0,
      table: {
        isBusy: false,
        fields: [
          { key: 'name', label: 'Schedule Name', visible: true },
          { key: 'schedule', label: 'Schedule', visible: true },
          { key: 'description', label: 'Description', visible: true },
          { key: 'state', visible: true },
          {
            key: 'scheduleTime',
            label: 'Schedule Time',
            formatter: (val) => {
              return val ? this.formatDateTime(this.parseISO(val)) : '';
            },
            visible: true
          },
          {
            key: 'lastAttemptTime',
            label: 'Last Attempt Time',
            formatter: (val) => {
              return val ? this.formatDateTime(this.parseISO(val)) : '';
            },
            visible: true
          },
          { key: 'timeZone', label: 'Time Zone', visible: true },
          {
            key: 'delete',
            label: 'Delete',
            visible: false
          }
        ],
        rows: 0,
        currentPage: 1,
        perPage: 8
      },
      loading: false,
      canEditMc: false
    };
  },
  async created() {
    this.canEditMc = await featureEnabled(LaunchDarklyKeys.EditMessageCenter);
  },
  computed: {
    tableColumns() {
      if (this.organization && this.organization.id && this.canEditMc) {
        return this.table.fields;
      }

      return this.table.fields.map((row) => {
        return row.visible === true;
      });
    },
    showEditMc() {
      return this.canEditMc;
    }
  },
  methods: {
    organizationSelected(organization) {
      this.reload++;
      this.organization = organization;
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    async items() {
      if (this.organization && this.organization.id) {
        try {
          const params = {
            organizationId: this.organization.id
          };
          const result = await MessageCenter.listSchedules(params);

          const activeItems = result.filter((schedule) => {
            return schedule.active === true;
          });

          return activeItems;
        } catch (error) {
          this.$emit('error', `Failed to load schedules ${error.message}`);
        }
      } else {
        return [];
      }
    },
    async deleteSchedule(scheduleName) {
      this.loading = true;

      try {
        await MessageCenter.deleteSchedule(this.organization.id, [scheduleName]);
        this.refreshTable();
        this.reload++;
      } catch (error) {
        this.$emit('error', `Failed to delete schedule: ${error.message}`);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-top: 20px;
  margin-left: 115px;
}
</style>
